<template>
    <div id="sidebar">
        <div class="container-fluid p-0">
            <nav id="navBar" style="height: 800px; overflow: auto">
                <ul>
                    <li v-for="m in menu.list"
                        :key="m"
                        :class="{ active: m.active }"
                        :hidden="!moduleRight(m.name, 'Lecture') && !m.subMenu">
                        <a href="#"
                           @click="menuClick($event, m)"
                           class="btn border-0 d-flex justify-content-between">
                            <div class="d-flex align-items-center">
                                <svg-icon :icon="m.icon" />
                                <span>{{ m.name }}</span>
                            </div>
                            <slot v-if="m.subMenu && m.active">
                                <svg-arrow-up />
                            </slot>
                            <slot v-if="m.subMenu && !m.active">
                                <svg-arrow-down />
                            </slot>
                        </a>

                        <ul v-if="m.subMenu && m.subMenu.length > 0 && m.active">
                            <li :class="{ active: s.active }"
                                v-for="s in m.subMenu"
                                :key="s"
                                :hidden='!moduleRight(s.name, "Lecture")'>
                                <a href="#" @click="menuClick($event, s)" class="btn border-0">
                                    <svg-icon :icon='s.icon' />
                                    <span>{{ s.name }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import svgIcon from './SvgIcon.vue';
import svgs from '../_svg/svgComponents';
import { useMenu } from '@/stores/menu';
import { useAuthStore } from '@/stores/auth.store';

export default {
    name: 'grappMenu',
    components: {
        svgIcon,
        ...svgs
    },
    watch: {
        '$route': function(route) {
            console.log(route);
            this.menu.list.map((m) => {

                m.active = m.stateName === route.name;

                if(m.subMenu) {
                    m.subMenu.map((sub) => {
                        sub.active = false;

                        if(sub.stateName === route.name) {
                            m.active = true;
                            sub.active = true;
                        }
                    });
                }
            });
        }
    },
    props: {},
    computed: {},
    mounted() {
        this.userModules = this.authStore.getUser.modules;
    },
    data() {
        return {
            userModules: []
        };
    },
    methods: {
        menuClick(event, menu) {
            event.preventDefault();
            this.menu.list.map((m) => m !== menu ? m.active = false : m.active = !m.active);
            if(!menu.stateName) return;
            this.$router.push({ name: menu.stateName });
            this.menu.hide();
        },
        moduleRight(moduleName, rightName) {
            let module = this.userModules.find(mod => mod.name === moduleName);
            if (module) {
                const right = module.rights.find(r => r.name === rightName);
                if (right)
                    return right.value;
                return false;
            }
            return false;
        },
    },
    setup() {
        return {
            menu: useMenu(),
            authStore : useAuthStore(),
        };
    }
};
</script>

<style>
#sidebar #navBar .btn {
    background-color: transparent;
}

#sidebar {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 72px;
    transform: translateX(-150%);
    transform-origin: left;
    width: 390px;
    z-index: 3;
}

.backoffice-layout #sidebar #navBar ul li {
    display: flex;
    flex-direction: column;
    margin: .5rem 0;
    padding: 0 !important;
    border-left: .125rem solid transparent;
    overflow: hidden;
}

#sidebar #navBar .btn span {
    color: #333 !important;
}

.btn svg + span, button svg + span {
    margin-left: .5rem;
    text-align: left;
}
</style>
