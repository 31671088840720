<template>
    <main role="main" class="h-100">
        <div id="mainContainer" class="no-topBar h-100">
            <router-view />
        </div>
    </main>
</template>

<script>
import { useMenu } from "@/stores/menu";
import svgs from '@/_svg/svgComponents';
export default {
    name: "App",
    data() {
        return {
            menuList: [
                {
                    icon: svgs.svgLayoutDashboard.raw,
                    name: 'Tableau de bord',
                    stateName: 'tableau-de-bord',
                    active: true
                },
                {
                    icon: svgs.svgTaskListStar.raw,
                    name: 'Rapport d\'activité',
                    stateName: 'admin-report-activity',
                },
                {
                    icon: svgs.svgOfficeFileAdobe.raw,
                    name: 'Documents',
                    stateName: 'admin-document',
                },
                {
                    name: 'Catalogues',
                    subMenu: [{
                        name: 'Administration des composants',
                        stateName: 'admin-components',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des réparations',
                        stateName: 'admin-repairs',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des produits',
                        stateName: 'admin-products',
                        icon: svgs.svgListBullets.raw,
                    }, {
                        name: 'Administration des modèles',
                        stateName: 'admin-models',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des appareils',
                        stateName: 'admin-devices',
                        icon: svgs.svgListBullets.raw,
                    },
                    {
                        name: 'Administration des goodies',
                        stateName: 'admin-goodies',
                        icon: svgs.svgListBullets.raw,
                    },{
                        name: 'Administration des accessoires',
                        stateName: 'admin-accessory',
                        icon: svgs.svgListBullets.raw,
                    },],
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Stocks',
                    subMenu: [
                        {
                            name: 'Administration des stocks',
                            stateName: 'admin-stocks',
                            icon: svgs.svgPerformancePhoneIncrease.raw,
                        },{
                            name: 'Transfert de stock',
                            stateName: 'admin-stocks-transfer',
                            icon: svgs.svgPerformancePhoneIncrease.raw,
                        },
                    ],
                    icon: svgs.svgPerformancePhoneIncrease.raw,
                },
                {
                    name: 'Exports',
                    subMenu: [
                        {
                            name: 'Export des ventes',
                            stateName: 'admin-export-vente',
                            icon: svgs.svgFileCsv.raw,
                        },
                        {
                            name: 'Export des rachats',
                            stateName: 'admin-export-rachat',
                            icon: svgs.svgFileCsv.raw,
                        },
                        {
                            name: 'Export des stocks',
                            stateName: 'admin-export-stock',
                            icon: svgs.svgFileCsv.raw,
                        },
                    ],
                    icon: svgs.svgFileCsv.raw,
                },
                {
                    name: 'Essentiels',
                    subMenu: [
                        {
                            name: 'Administration des marques',
                            stateName: 'admin-brands',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des groupes',
                            stateName: 'admin-groups',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des types de composant',
                            stateName: 'admin-component-type',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des types de goodies',
                            stateName: 'admin-goodies-type',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des types d\'accessoire',
                            stateName: 'admin-accessory-type',
                            icon: svgs.svgListBullets.raw,
                        },
                        {
                            name: 'Administration des textures',
                            stateName: 'admin-texture',
                            icon: svgs.svgListBullets.raw,
                        },
                    ],
                    icon: svgs.svgListBullets.raw,
                },
                {
                    name: 'Paramètres',
                    icon: svgs.svgCog.raw,
                    subMenu: [
                        {
                            name: 'Administration des objectifs',
                            stateName: 'admin-goals',
                            icon: svgs.svgBusinessClimbTop1.raw
                        },{
                            name: 'Administration des lieux',
                            stateName: 'admin-places',
                            icon: svgs.svgPinLocation.raw,
                        },{
                            name: 'Administration des utilisateurs',
                            stateName: 'admin-users',
                            icon: svgs.svgSingleNeutralCircle.raw,
                        },{
                            name: 'Appareil temporaire',
                            stateName: 'admin-temp-device',
                            icon: svgs.svgSingleNeutralCircle.raw,
                        },
                    ]
                }
            ]
        };
    },
    mounted() {
        this.menu.init(this.menuList);
    },
    setup() {
        return {
            menu: useMenu(),
        };
    },
};
</script>

<style>
#navBar ul li ul li .active {
    color: #0e626f !important;
}
</style>
