<template>
    <section id='mainContent' class='h-100'>
        <div class='container-fluid h-100'>
            <div class='card h-100 shadow-sm'>
                <div class='card-body d-flex flex-column h-100'>
                    <h3 class='card-title'  v-if="lecture">Export des stocks</h3>
                    <div class='row mb-2 align-items-center'>
                        <div class='col-md-2'>
                            <select class='form-control'
                                    v-model='place'
                                    @change='getList'
                                    required>
                                <option :value=null>Sélectionner un lieu</option>
                                <option v-for='(place, key) in places'
                                        :key='key'
                                        :value='place.placeId'>
                                    {{ place.name }}
                                </option>
                            </select>
                        </div>
                        <div class='col-md-2'>
                            <select class='form-control'
                                    v-model='type'
                                    @change='getList'
                                    required>
                                <option :value="'components'">Composant</option>
                                <option :value="'devices'">Appareil</option>
                                <option :value="'goodies'">Goodies</option>
                            </select>
                        </div>
                        <div class='col-auto'>
                            <button type="button"
                                    class="btn--icon btn--outline btn--small move-left"
                                    @click='getList'><svg-search /></button>
                        </div>
                    </div>
                    <div class='flex-fill' :hidden="!lecture">
                        <table ref='table'
                               id='table'
                               class='table table-striped table-hover table-handyRows w-100'></table>
                    </div>
                    <notif type='error'
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if='!lecture'/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';

import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';

import notif from '@/components/Notif.vue';
import { DateTime } from 'luxon';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';
import { useSearchStore } from '@/stores/search.store';
import svgComponents from '../../_svg/svgComponents';
export default {
    name: 'ExportStockList.vue',
    components: { notif, ...svgComponents },
    data() {
        return {
            componentColumns: [
                {
                    data: 'placeId',
                    title: 'Id lieu',
                },
                {
                    data: 'placeName',
                    title: 'Lieu'
                },
                {
                    data: 'name',
                    title: 'Composant'
                },
                {
                    data: 'componentId',
                    title: 'Id Composant',
                },
                {
                    data: 'quantity',
                    title: 'Quantité',
                },
                {
                    data: 'ugs',
                    title: 'UGS',
                },
                {
                    data: 'brandName',
                    title: 'Marque',
                },
                {
                    data: 'componentTypeName',
                    title: 'Type composant'
                },
                {
                    data: 'componentQualityName',
                    title: 'Qualité',
                },
                {
                    data: 'colorName',
                    title: 'Couleur',
                },
                {
                    data: 'Tools',
                    title: 'Outil',
                    render: (data) => {
                        return data ? 'Oui' : 'Non';
                    }
                },
                {
                    data: 'supplierPrice',
                    title: 'Prix fournisseur',
                }
            ],
            deviceColumns: [
                {
                    data: 'placeId',
                    title: 'Id Propriétaire',
                },
                {
                    data: 'ownerPlaceName',
                    title: 'Propriétaire',
                },
                {
                    data: 'stockPlaceId',
                    title: 'Id lieu stockage',
                },
                {
                    data: 'stockPlaceName',
                    title: 'Lieu stockage',
                },
                {
                    data: 'quantity',
                    title: 'Quantité',
                },
                {
                    data: 'stockId',
                    title: 'Stock Id',
                },
                {
                    data: 'name',
                    title: 'Nom',
                },
                {
                    data: 'gencode',
                    title: 'gencode'
                },
                {
                    data: 'serialNumber',
                    title: 'Numéro de série'
                },
                {
                    data: 'gradeName',
                    title: 'Etat'
                },
                {
                    data: 'productTypeName',
                    title: 'Type produit'
                },
                {
                    data: 'unitPurchasePrice',
                    title: 'Prix d\'achat'
                }
            ],
            goodiesColumns: [
                {
                    data: 'placeId',
                    title: 'Id lieu',
                },
                {
                    data: 'placeName',
                    title: 'Lieu'
                },
                {
                    data: 'name',
                    title: 'Goodies'
                },
                {
                    data: 'goodiesId',
                    title: 'Id Goodies',
                },
                {
                    data: 'quantity',
                    title: 'Quantité',
                },
                {
                    data: 'size',
                    title: 'Taille',
                },
                {
                    data: 'price',
                    title: 'Prix',
                },
                {
                    data: 'sign',
                    title: 'Marque',
                }
            ],
            rows: [],
            places: [],
            allPlaces: [],
            sign: null,
            table: null,
            error: false,
            loading: false,
            place: null,
            type: 'components',
            init: false,
            lecture: false,
            modification: false,
            filename: DateTime.now().toISO({
                suppressSeconds: true, suppressMilliseconds: true, includeOffset: false, extendedZone: false })
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Export des stocks', 'Modification');
        this.lecture = await this.authStore.moduleRight('Export des stocks', 'Lecture');
        if(this.lecture) {
            await this.getPlaces();
            if(this.search.getSearch('exportStock')) {
                this.place = this.search.getSearch('exportStock').place;
                this.type = this.search.getSearch('exportStock').type;
            }
            await this.getList();
        }
    },
    methods: {
        async getPlaces() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/place`,
                );
                this.places = res.data.reduce((curr, place) => {
                    if (['6', '999'].indexOf(place.placeId) === -1 && place.status) {
                        curr.push(place);
                    }
                    return curr;
                }, []);
                this.allPlaces = JSON.parse(JSON.stringify(this.places));
                if (this.authStore.getUser.places.length === 1 && this.authStore.getUser.places[0].id !== '2') {
                    this.sign = this.authStore.getUser.places[0].name.indexOf('BMP') !== -1 ? 'BMP' : 'C&R';
                    this.oneSign = true;
                } else if (this.authStore.getUser.places[0].id !== '2') {
                    let BMP = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('BMP') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    let CR = this.authStore.getUser.places.reduce((curr, place) => {
                        if (place.name.indexOf('C&R') !== -1) curr.push(place);
                        return curr;
                    }, []);
                    if(BMP.length > 0 && CR.length > 0) {
                        this.sign = null;
                    }else if (BMP.length > 0) {
                        this.sign = 'BMP';
                        this.oneSign = true;
                    }else {
                        this.sign = 'C&R';
                        this.oneSign = true;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getList() {
            let url = `${window.location.origin}/api/crd/stocks/${this.type}/place/${this.place}`;
            try {
                if(this.place) {
                    this.search.set('exportStock', { type: this.type, place: this.place });
                    this.loader.show('load');
                    const res = await this.axios.get(url);
                    this.rows = res.data;
                    if(this.table) {
                        this.table.clear();
                        this.table.destroy();
                        $("#table").empty();
                    }
                    if(this.type === 'components') {
                        this.table = this.initDt(this.componentColumns, this.rows);
                    } else if(this.type === 'devices') {
                        this.table = this.initDt(this.deviceColumns, this.rows);
                    }else {
                        this.table = this.initDt(this.goodiesColumns, this.rows);
                    }

                }
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);
            const dtOptions = {
                data,
                columns,
                buttons: [{ extend: 'csv', text: 'Export CSV', filename: this.type + '_stock_'+this.filename },'colvis'],
                deferRender: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: false,
                scrollResize: true,
                pageLength: 100,
                scrollY: '50vh',
                scrollX: true,
                order: [[4, 'desc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Blpti',
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup() {
        return {
            authStore: useAuthStore(),
            loader: useLoader(),
            search: useSearchStore()
        };
    }
};
</script>

<style scoped>
.wrapperClass.vuejs3-datepicker__inputvalue {
    padding: 6px 12px;
    border: #666666 1px solid;
}
@import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
</style>
