<template>
    <section id='mainContent' class='h-100'>
        <div class='container-fluid h-100'>
            <div class='card h-100 shadow-sm'>
                <div class='card-body d-flex flex-column h-100'>
                    <h3 class='card-title'  v-if="lecture">Liste des appareils à créer</h3>
                    <div class='flex-fill' :hidden="!lecture">
                        <table
                            ref='table'
                            class='table table-striped table-hover table-handyRows'></table>
                    </div>
                    <notif type='error'
                           text="Vous n'êtes pas autorisé à consulter ce module."
                           v-if='!lecture'/>
                </div>
                <div class='card-footer'>
                    <div class='row justify-content-center'>
                        <div class='col-auto'>
                            <notif type='error'
                                   text="Un problème est survenu pendant la création de l'appareil"
                                   v-if='error'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';

import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';
import notif from '@/components/Notif.vue';
import { DateTime } from 'luxon';
import { useAuthStore } from '@/stores/auth.store';
import { useLoader } from '@/stores/loader';

export default {
    name: 'TempDeviceList.vue',
    components: { notif },
    data() {
        return {
            columns: [
                {
                    data: 'placeName',
                    title: 'Lieu',
                },
                {
                    data: 'gencode',
                    title: 'GenCode',
                },
                {
                    data: 'productTypeName',
                    title: 'Type produit',
                },
                {
                    data: 'descriptionArticle',
                    title: 'Description',
                },
                {
                    data: 'numeroSerieArticle',
                    title: 'Numéro de serie',
                },
                {
                    data: 'quantiteAchete',
                    title: 'Quantité',
                },
                {
                    data: 'prixUnitaireAchat',
                    title: 'Prix unitaire achat',
                },
                {
                    data: 'prixUnitaireVente',
                    title: 'Prix unitaire vente',
                },
                {
                    data: 'idClient',
                    title: 'Id client',
                },
                {
                    data: 'idProduct',
                    title: 'Id Produit',
                },
                {
                    data: 'idEtat',
                    title: 'Etat'
                },
                {
                    data: 'capaciteMemoire',
                    title: 'Capacité memoire'
                },
                {
                    data: 'createdDate',
                    title: 'Date création',
                    render: (data) => {
                        return data ? DateTime.fromISO(data, { setZone: "Europe/Paris" }).toFormat('yyyy-MM-dd HH:mm:ss') : '';
                    }
                }
            ],
            rows: [],
            table: null,
            error: false,
            loading: false,
            init: false,
            lecture: false,
            modification: false
        };
    },
    async mounted() {
        await this.getList();
        this.modification = await this.authStore.moduleRight('Appareil temporaire', 'Modification');
        this.lecture = await this.authStore.moduleRight('Appareil temporaire', 'Lecture');
    },
    methods: {
        async getList() {
            try {
                this.loader.show('load');
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/devices/temp`,
                );
                this.rows = res.data;

                this.table = this.initDt(this.columns, this.rows);
            } catch (err) {
                console.log(err);
            } finally {
                this.loader.hide('load');
            }
        },
        goTo(device) {
            this.$router.push(`temp-device/${device.gencode}`);
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                stateSave: true,
                buttons: ['searchBuilder'],
                deferRender: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: false,
                scrollResize: true,
                pageLength: 100,
                scrollY: 100,
                scrollX: true,
                order: [[12, 'desc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Bflpti',
                stateLoadParams: function(settings, data) {
                    if (!this.init) {
                        var state = this.table.state.loaded();
                        data.searchBuilder = state.searchBuilder;
                    }
                },
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);
                    // Restore state saved values
                    var state = vm.table.state.loaded();
                    if (state) {
                        console.log(state);
                    }

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        if (row) {
                            vm.goTo(row);
                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
    setup() {
        return {
            authStore: useAuthStore(),
            loader: useLoader()
        };
    }
};
</script>

<style scoped></style>
