<template>
    <section id="mainContent" class="h-100">
        <div class="container-fluid h-100">
            <div class="card h-100 shadow-sm">
                <div class="card-body d-flex flex-column h-100">
                    <h3 class="card-title">Historique</h3>
                    <div class="flex-fill">
                        <table
                            ref="table"
                            class="table table-striped table-hover table-handyRows"></table>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <notif type="error"
                                   text="Un problème est survenu pendant la création du composant"
                                   v-if="error"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import notif from '../../components/Notif.vue';
import 'datatables.net/js/jquery.dataTables';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import 'datatables.net-keytable-bs4/js/keyTable.bootstrap4';
import 'datatables.net-select-bs4/js/select.bootstrap4';
import 'datatables.net-searchbuilder-bs4/js/searchBuilder.bootstrap4';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4';
import 'datatables.net-plugins/features/scrollResize/dataTables.scrollResize';
import 'datatables.net-buttons/js/buttons.html5';

export default {
    name: 'StockHistoryList',
    components: {
        notif
    },
    data() {
        return {
            columns: [
                {
                    data: 'stockHistoryId',
                    title: 'id',
                },
                {
                    data: 'name',
                    title: 'Nom',
                },
                {
                    data: 'manufacturerRef',
                    title: 'Référence',
                },
                {
                    data: 'type',
                    title: 'Type',
                    render: (data) => {
                        return data === 'in' ? 'Entrée' : 'Sortie';
                    }
                }, {
                    data: 'quantity',
                    title: 'Quantité',
                },
                // {
                //     data: 'qrCode',
                //     title: 'qrCode',
                //     render: (data) => {
                //         return data !== null ? `<span class="text-center"><img src="${data}" style="max-height: 80px" /></span>` : data;
                //     }
                // },
                {
                    data: 'supplier',
                    title: 'Fournisseur',
                },
                {
                    data: 'unitPrice',
                    title: 'Prix unitaire',
                },
                {
                    data: 'comment',
                    title: 'Commentaire',
                },
                {
                    data: 'repairTypeName',
                    title: 'Réparation',
                },
                {
                    data: 'createdDate',
                    title: 'Date',
                    render: (data) => {
                        return data;
                    }
                },
                {
                    data: 'placeName',
                    title: 'Lieux',
                }
            ],
            rows: [],
            detail: {},
            error: false,
            table: null,
        };
    },
    mounted() {
        this.getList();
        this.getDetail();
    },
    methods: {
        async getList() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stock/${this.$route.params.id}/history`
                );
                this.rows = res.data;
                this.rows.map(r => {
                    if(r.goodiesId) {
                        r.name = `${r.goodiesTypeName} ${r.gender ? r.gender : ''}`;
                        r.name += `${r.genericColorName ? r.genericColorName : ''}`;
                        r.name += ` ${r.sign} ${r.size ? ' - ' + r.size : ''}`;
                        r.name += `${r.batch ? '(Lot de ' + r.batchQuantity + ')' : ''}`;
                    }
                });
                this.table = this.initDt(this.columns, this.rows);
            } catch (err) {
                console.log(err);
                this.error = true;
            }
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/stock/${this.$route.params.id}/detail`
                );
                this.detail = res.data;
            } catch (err) {
                console.log(err);
                this.error = true;
            }
        },
        initDt(cols, content) {
            // kill refs
            const columns = _.cloneDeep(cols);
            const data = _.cloneDeep(content);

            const dtOptions = {
                data,
                columns,
                buttons: ['searchBuilder'],
                deferRender: true,
                stateSave: true,
                language: {
                    url: 'https://cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json',
                },
                responsive: true,
                scrollResize: true,
                pageLength: 100,
                scrollY: 100,
                order: [[9, 'asc']],
                select: {
                    style: 'api',
                    toggleable: false,
                },
                dom: 'Bflpti',
                initComplete: () => {
                    const vm = this;
                    vm.$emit('initComplete', vm.table);

                    $(vm.$refs.table).on('click', 'tr', function() {
                        const row = vm.table.row(this).data();
                        if (row) {
                            console.log(row);
                        }
                    });
                },
            };

            return $(this.$refs.table).DataTable(dtOptions);
        }
    },
};
</script>

<style scoped>
/*.table-hover tr {*/
/*    cursor: pointer;*/
/*}*/
</style>
