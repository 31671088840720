import { defineStore } from 'pinia';

export const useMenu = defineStore('menu', {
    state: () => ({
        list: [],
        displayed: false
    }),
    getters: {},
    actions: {
        init(menu) {
            this.list = menu;
        },
        toggleDisplay() {
            this.displayed = !this.displayed;
        },
        show() {
            this.displayed = true;
        },
        hide() {
            this.displayed = false;
        }
    }
});