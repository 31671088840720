<template>
    <section id='mainContent' v-if='detail'>
        <div class='container-fluid'>
            <div class='row'>
                <div class='col-sm-8'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title' v-if='detail.accessoryId'>
                                {{ detail.name }}
                            </h3>
                            <h3 class='card-title' v-else>
                                Création d'un accessoire
                            </h3>
                            <div class='row'>
                                <div class='col-sm-6 mt-6'>
                                    <form>
                                        <fieldset>
                                            <legend>Accessoire</legend>
                                            <!-- TYPE accessoire -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Type d'accessoire <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.accessoryTypeId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.accessoryTypeId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(accessoryType, key) in accessoryTypes'
                                                                    :key='key'
                                                                    :value='accessoryType.accessoryTypeId'>
                                                                {{ accessoryType.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.accessoryTypeId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un Type d'accessoire
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- TEXTURE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Texture <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.textureId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.textureId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(texture, key) in textures'
                                                                    :key='key'
                                                                    :value='texture.textureId'>
                                                                {{ texture.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.textureId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner une texture
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- MARQUE ACCESSOIRE-->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Marque accessoire <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.brandId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.brandId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(brand, key) in brands'
                                                                    :key='key'
                                                                    :value='brand.brandId'>
                                                                {{ brand.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.brandId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner une marque
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- TYPE PRODUIT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Type d'appareil<abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.productTypeId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.productTypeId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(productType, key) in productTypes'
                                                                    :key='key'
                                                                    :value='productType.productTypeId'>
                                                                {{ productType.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.productTypeId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un Type d'appareil
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- MARQUE MODEL-->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Marque modèle<abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.modelBrandId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.modelBrandId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(brand, key) in brands'
                                                                    :key='key'
                                                                    :value='brand.brandId'>
                                                                {{ brand.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.modelBrandId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner une marque pour le modèle
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- MODELE -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Modèle <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select :class="{'is-invalid': v$.detail.modelId.$error,}"
                                                                class='form-control'
                                                                v-model='detail.modelId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(model, key) in models'
                                                                    :key='key'
                                                                    :value='model.modelId'>
                                                                {{ model.name }}
                                                            </option>
                                                        </select>
                                                        <p v-if='v$.detail.modelId.$error'
                                                           class='invalid-feedback'>
                                                            Veuillez sélectionner un modèle
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- PRIX -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Prix de vente TTC <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <input type='number'
                                                               min='0'
                                                               class='form-control'
                                                               :class="{'is-invalid': v$.detail.price.$error}"
                                                               v-model='detail.price' >
                                                        <p v-if='v$.detail.price.$error' class='invalid-feedback'>
                                                            Veuillez saisir prix</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- COULEUR -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Couleur</label>
                                                <div class='col-6'>
                                                    <div class='col-12'>
                                                        <select class='form-control'
                                                                v-model='detail.colorId'
                                                                required>
                                                            <option :value="undefined"></option>
                                                            <option v-for='(color, key) in colors'
                                                                    :key='key'
                                                                    :value='color.colorId'>
                                                                {{ color.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- ETAT -->
                                            <div class='row form-group'>
                                                <label class='col-6 col-form-label'>Etat <abbr>*</abbr></label>
                                                <div class='col-6'>
                                                    <div class='row'>
                                                        <div class='col'>
                                                            <div
                                                                class='custom-control custom-radio custom-control-inline'
                                                                v-for='s in status'
                                                                :key='s.value'>
                                                                <input class='custom-control-input'
                                                                       type='radio'
                                                                       name='status'
                                                                       :class="{'is-invalid': v$.detail.status.$error}"
                                                                       :id="'status_'+s.value"
                                                                       :value='s.value'
                                                                       v-model='detail.status'>
                                                                <label class='custom-control-label'
                                                                       :for="'status_'+s.value"
                                                                       :class="detail.status === s.value ? s.class : ''">{{ s.label
                                                                       }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class='row' v-if='v$.detail.status.$error'>
                                                        <div class='col'>
                                                            <input type='hidden' class='form-control is-invalid' >
                                                            <p class='invalid-feedback'>
                                                                Veuillez sélectionner un état
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </form>
                                </div>
                                <div class='col-sm-6 mt-6' v-if='detail.accessoryId'>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-center'>
                                <div class='col-auto'>
                                    <notif type='success'
                                           text='Sauvegarde effectuée'
                                           v-if='success'/>
                                    <notif type='error'
                                           text='Un problème est survenu durant la sauvegarde'
                                           :message='this.msgError'
                                           v-if='error'/>
                                </div>
                            </div>
                            <div class='row is-animated d-flex justify-content-between'>
                                <div class="col-auto">
                                    <button v-if="!loading && detail.accessoryId && modification"
                                            class="btn--color-success"
                                            @click="createAccessory">
                                        <span>Créer un nouvel accessoire</span>
                                        <svg-add />
                                    </button>
                                </div>
                                <div class='col-auto'>
                                    <button
                                        v-if='!loading && modification'
                                        class='btn--color-success'
                                        @click='submit'
                                        :disabled='v$.$error'>
                                        <span>Sauvegarder</span>
                                        <svg-floppy-disk />
                                    </button>
                                    <button
                                        v-if='loading'
                                        class='btn--color-success'
                                        type='button'
                                        disabled>
                                        <span>Sauvegarde en cours...</span>
                                        <figure class='spin--infinite mr-2'>
                                            <svg-floppy-disk />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='col-sm-4'>
                    <div class='card shadow-sm'>
                        <div class='card-body'>
                            <h3 class='card-title'>Image</h3>
                            <div class='row justify-content-center'>
                                <div class='col-auto' v-if='detail.image && !image.error'>
                                    <img class='img-fluid' :src='detail.image' >
                                </div>
                                <div class='col-auto' v-if='!detail.image || image.error'>
                                    <notif type='error'
                                           :text='image.errorText'/>
                                </div>
                            </div>
                        </div>
                        <div class='card-footer'>
                            <div class='row justify-content-end'>
                                <div class='col-auto is-animated d-flex'>
                                    <input type='file' id='fileUpload' @change='handleImage' hidden >
                                    <button v-if='!image.loading && modification'
                                            class='btn--outline'
                                            @click='selectFile'>
                                        <span>Modifier l'image</span>
                                        <svg-download />
                                    </button>
                                    <button v-if='image.loading'
                                            class=''
                                            type='button'
                                            disabled>
                                        <span>Upload en cours...</span>
                                        <figure class='spin--infinite ml-2'>
                                            <svg-download />
                                        </figure>
                                    </button>
                                </div>
                            </div>
                            <div class='row justify-content-end text-demat mr-1 mt-1 font-italic'>
                                <small>Format: JPG/PNG - Max: 300Ko</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useAuthStore } from '@/stores/auth.store';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import svgComponent from '../../_svg/svgComponents';
import notif from '@/components/Notif.vue';

export default {
    name: 'AccessoryDetail',
    components: { ...svgComponent, notif },
    data() {
        return {
            detail: [],
            models: [],
            accessoryTypes: [],
            textures: [],
            productTypes: [],
            colors: [],
            brands: [],
            loading: false,
            success: false,
            error: false,
            msgError: null,
            isCreate: false,
            status: [
                {
                    value: true,
                    label: 'Actif',
                    label2: 'Oui',
                    class: 'text-success',
                },
                {
                    value: false,
                    label: 'Inactif',
                    label2: 'Non',
                    class: 'text-danger',
                },
            ],
            lecture: false,
            modification: false,
            image: {
                loading: false,
                error: false,
                errorText: 'Aucune image',
            },
        };
    },
    async mounted() {
        this.modification = await this.authStore.moduleRight('Administration des accessoires', 'Modification');
        this.lecture = await this.authStore.moduleRight('Administration des accessoires', 'Lecture');
        if (this.$route.params.id === 'create') {
            if(!this.modification)
                this.$router.push('/admin/accessory');
            this.isCreate = true;
            this.createNewDetail();
        } else {
            if(this.lecture) {
                await this.getDetail();
            }else {
                this.$router.push('/admin/accessory');
            }

        }
        if(this.lecture) {
            await this.getBrands();
            await this.getProductTypes();
            await this.getColors();
            await this.getAccessoryTypes();
            await this.getTextures();
        }

    },
    watch: {
        'detail.modelBrandId': async function(val, oldVal) {
            if (val !== oldVal && this.detail.productTypeId)
                await this.getModels();
        },
        'detail.productTypeId': async function(val, oldVal) {
            if (val !== oldVal && this.detail.modelBrandId)
                await this.getModels();
        }
    },
    methods: {
        createNewDetail() {
            this.detail = {
                accessoryId: null,
                accessoryTypeId: null,
                textureId: null,
                price: null,
                modelId: null,
                colorId: null,
                brandId: null,
                status: true,
                modelBrandId: null
            };
        },
        createAccessory() {
            this.createNewDetail();
            this.$router.push('create');
        },
        async getDetail() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/accessory/${this.$route.params.id}`,
                );
                this.detail = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        async getModels() {
            try {
                if (this.detail.modelBrandId && this.detail.productTypeId) {
                    const res = await this.axios.get(
                        `${window.location.origin}/api/crd/models/brand/${this.detail.modelBrandId}/productType/${this.detail.productTypeId}`,
                    );
                    this.models = res.data.filter(m => m.status === true);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async getProductTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/productType`,
                );
                this.productTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getAccessoryTypes() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/accessoryTypes`,
                );
                this.accessoryTypes = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getTextures() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/texture`,
                );
                this.textures = res.data.filter(pt => pt.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getColors() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/color`,
                );
                this.colors = res.data.filter(c => c.status === true);
            } catch (err) {
                console.log(err);
            }
        },
        async getBrands() {
            try {
                const res = await this.axios.get(
                    `${window.location.origin}/api/crd/essentials/brand`,
                );
                this.brands = res.data;
            } catch (err) {
                console.log(err);
            }
        },
        goTo(id) {
            this.$router.push(`${id}`);
        },
        selectFile() {
            document.getElementById('fileUpload').click();
        },
        handleImage(e) {
            const img = e.target.files[0];

            if (['image/jpeg', 'image/png'].indexOf(img.type) === -1) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image au format PNG ou JPEG.';
            }

            if (img.size > 300000) {
                this.image.error = true;
                return this.image.errorText = 'Veuillez uploader une image inférieure à 300ko.';
            }

            const reader = new FileReader();

            reader.onload = (e) => {
                this.uploadImage(e.target.result);
            };

            reader.readAsDataURL(img);
        },
        async uploadImage(image) {
            this.image.loading = true;
            this.image.error = false;

            try {
                const data = {
                    newImage: image,
                    oldImage: this.detail.image,
                };

                const res = await this.axios.post(`${window.location.origin}/api/crd/accessory/${this.detail.accessoryId}/image`, data);
                this.image.loading = false;
                this.detail.image = res.data.image;
            } catch (err) {
                this.image.error = true;
                this.image.loading = false;
            }
        },
        async submit(e) {
            e.preventDefault();

            this.v$.$touch();

            if (!this.v$.$error) {
                this.loading = true;
                this.error = false;
                this.success = false;

                try {
                    const res = await this.axios.post(
                        `${window.location.origin}/api/crd/accessory`,
                        this.detail,
                    );
                    this.success = true;
                    this.loading = false;
                    this.detail = res.data;
                } catch (err) {
                    this.error = true;
                    this.loading = false;
                    this.msgError = err.response.data.message;
                }
            }
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
            authStore: useAuthStore()
        };
    },
    validations() {
        return {
            detail: {
                $autoDirty: true,
                accessoryTypeId: { required },
                textureId: { required },
                brandId: { required },
                modelId: { required },
                productTypeId: { required },
                price: { required },
                status: { required },
                modelBrandId: { required },
            },
        };
    },

};
</script>

<style scoped>

</style>
