<template>
    <div class="backoffice-layout theme-bookstore" :class="classEnv">
        <grapp-menu :class="{ 'sidebar--active': menu.displayed }" />
        <header id='mainHeader'>
            <div class='container-fluid row align-items-center justify-content-between m-0  p-0 h-100' >
                <div id='mainLogo' class='col-lg-auto d-none d-lg-block p-0'>
                    <button ui-sref='css-home' @click="this.$router.push({ name: 'tableau-de-bord' })">
                        <img src='../assets/grapp/dist/assets/images/cerebro-removebg-preview6.png'
                             alt='CEREBRO Back-office'>
                    </button>
                </div>
                <div class='col-auto p-0 justify-content-start'>
                    <button type='button' @click='menu.toggleDisplay' class='btn btn--menu btn--color-black py-1 mx-2 px-2'>
                        <svg-menu />
                        <span class='d-none d-sm-block'>Menu</span>
                    </button>
                </div>
                <div class='col col-xl-3 p-2'>
                    <h1 class='mt-n1'><small><strong>CEREBRO ({{ version }})</strong></small></h1>
                    <h2>{{ $route.meta.subtitle }}</h2>
                </div>
                <div class='col col-xl-5 text-center d-none d-lg-block'>
                    <h3>{{ date }}<br>{{ time }}</h3>
                </div>
                <div class='col-auto d-flex align-items-center justify-content-end'>
                    <h2 class='d-none d-lg-block pr-2'>{{ authStore.getDisplayName.toUpperCase() }}</h2>
                    <button @click='logout'>
                        <svg-logout />
                    </button>
                </div>
            </div>
        </header>
        <grapp-loader :active="loader.is('load')"
                      :message="'Chargement en cours...'"/>
        <router-view/>
    </div>
</template>

<script>

import { useAuthStore } from '@/stores/auth.store';
import svgComponents from '../_svg/svgComponents';
import svgs from '../_svg/svgComponents';
import svgIcon from '@/components/SvgIcon.vue';
import grappLoader from '@/components/Loader.vue';
import { useLoader } from '@/stores/loader';
import grappMenu from '@/components/Menu.vue';
import { useMenu } from '@/stores/menu';

const dateOpts = { weekday: 'long', month: 'long', day: 'numeric' };
const timeOpts = { hour: '2-digit', minute: '2-digit' };

export default {
    // eslint-disable-next-line vue/multi-word-component-names,vue/no-reserved-component-names
    name: 'Main',
    components: {
        grappLoader,
        ...svgComponents,
        grappMenu
    },
    data() {
        return {
            date: new Date().toLocaleDateString('fr-FR', dateOpts),
            time: new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h'),
            classEnv: null,
            displayName: '',
            version: process.env.VUE_APP_VERSION,
        };
    },
    created() {
        setInterval(() => {
            this.date = new Date().toLocaleDateString('fr-FR', dateOpts);
            this.time = new Date()
                .toLocaleTimeString('fr-FR', timeOpts)
                .replace(':', 'h');
        }, 5000);
    },
    mounted() {
        this.getEnv();
        this.displayName = this.authStore.getUser.firstName ? this.authStore.getUser.firstName + ' ' + this.authStore.getUser.lastName : this.authStore.getUser.login;
        // this.menu.init(this.menuList);
        // this.version = process.env.VERSION
    },
    methods: {
        logout() {
            return this.authStore.logout();
        },
        getEnv() {
            if (document.location.href.search('localhost') !== -1) {
                this.classEnv = 'is-dev';
            } else if (document.location.href.search('ready2dev') !== -1) {
                this.classEnv = 'is-preprod';
            } else {
                this.classEnv = 'is-prod';
            }
        }
    },
    setup() {
        return {
            authStore : useAuthStore(),
            loader: useLoader(),
            menu: useMenu()
        };
    }
};
</script>

<style scoped>
img {
    max-width: 155px;
    height: auto;
}
.sidebar--active {
    transition: all .3s ease-in-out 0s !important;
    transform: translateX(0) !important;
    opacity: 1 !important;
}

.backoffice-layout {
    grid-template-columns: 0;
}

.btn svg + span, button svg + span {
    margin-left: .5rem;
    text-align: left;
    color: #333 !important;
}

.backoffice-layout #mainHeader h1, .backoffice-layout #mainHeader h2 {
    color: #333!important;
    font-size: 1rem;
}

.btn.btn--menu {
    border: 1px solid black !important;
    color: #333 !important;
}

li a .page-item .active .page-link {
    background-color: #0097a7 !important;
    border-color: #0097a7 !important;
}
.btn span.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}

.btn span.badge.badge-danger{
    color: #fff;
    background-color: #dc3545;
}
</style>
